import { UtilService } from './../../../services/util.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { LoginService } from '../../../services/login/login.service';
import { ImagesService } from '../../../services/images/images.service';
import { ImagenesFaltantesService } from '../../../services/imagenes-faltantes/imagenes-faltantes.service';
import imageCompression from 'browser-image-compression';
import { EmpresaService } from '../../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modal-imagenes-faltantes',
  templateUrl: './modal-imagenes-faltantes.component.html',
  styleUrls: ['./modal-imagenes-faltantes.component.scss']
})

export class ModalImagenesFaltantesComponent implements OnInit {
  public formData = new FormData();
  files: File[] = [];
  maximumSize = false;
  public preview = false;
  public formGroup = this.formBuilder.group({
    empresa: 'Kabik',
    clave: '',
    quien: this.login.getUserData().correo
  });
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ModalImagenesFaltantesComponent>,
    private formBuilder: FormBuilder,
    private login: LoginService,
    private imagesService: ImagesService,
    private imagenesFaltantesService: ImagenesFaltantesService,
    private utilService: UtilService,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
      const empresa =  id === '1' || id === 1 ?'Kabik':'Letech';
      this.formGroup.patchValue({ empresa: empresa});

    });
  }

  ngOnInit() {
    this.patchValue(this.data);
  }

  public patchValue(data: any) {
    this.formGroup.patchValue({
      clave: data.ClaveArticulo
    });
  }

  modalClose(): void {
    this.imagesService.setImagenEnEdicion(this.data.ClaveArticulo, 'NO').subscribe((res) => { });
    this.matDialogRef.close(false);
  }

  onSelect(event: any) {
    const file = event.addedFiles[0];
    this.compressImage(file)
      .then((compressedFile) => {
        this.files.push(compressedFile);
      })
      .catch((error) => {
        console.error('Error compressing the file:', error);
      });
  }

  async compressImage(file: File): Promise<File> {
    const options = {
      maxSizeMB: 1, // Limitar tamaño máximo a 1MB
      maxWidthOrHeight: 1024, // Tamaño máximo de ancho o alto
      useWebWorker: true, // Usar un trabajador web para compresión más rápida
      fileType: 'image/webp' // Convertir a WebP
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      throw new Error('Error during image compression');
    }
  }

  onRemove(file: File) {
    this.files = this.files.filter(f => f !== file);
  }

  onSubmit() {
    if (this.files === null) {
      Swal.fire({
        type: 'warning',
        title: 'No ha seleccionado la imagen',
        text: 'Por favor, seleccione una imagen'
      });
      return;
    }
    console.log('Files ready to upload:', this.files);
    for (const [key, value] of Object.entries(this.formGroup.value)) {
      this.formData.append(key, value.toString());
    }

    this.insert();
  }

  insert() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    // this.formData.append('files[]', this.files[0], this.files[0].name);
    this.files.forEach((file, index) => {
      // Cambiamos el nombre del archivo a .webp si ha sido comprimido en ese formato
      const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".webp";
      const webpFile = new File([file], newFileName, { type: 'image/webp' });

      // Agregamos el archivo con el nuevo nombre al FormData
      this.formData.append(`files[]`, webpFile, newFileName);
    });
    this.formData.append('opcion', 'insert');
    this.formData.append('upload', 'true');
    this.imagenesFaltantesService.insert(this.formData).then((res) => {
      if (res.message == 'true') {
        this.imagenesFaltantesService.updateObservable(res);
        Swal.fire({
          type: 'success',
          title: 'Correcto',
          text: 'Se ha guardado correctamente',
          footer: 'Excelente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error(res.message);
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema'
      });
    });
  }
}
