import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { DataFactura, DataFile } from '../../model/DataFactura';
import { DataBuissness } from '../../model/DataBuissness';
import { DataUtilidades,DataFacturaPagada } from '../../model/DataUtilidadHistorico';
import { Observable, throwError } from 'rxjs';
import { DataCFDI, DataRegimenFiscal } from '../../model/DataAddress';
import { map, catchError, retry } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EmpresaService } from '../../../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// src\app\views\clientes\model\DataUtilidadHistorico.ts

export interface Respuesta {
  message?: string;
  error?: string;
}


@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {
  private apiUrl = environment.apiUrl;
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private empresaService: EmpresaService

  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }
  public getFacturas = (fechaDeCorte: string, client_id: number): Observable<DataFactura[]> => {
    const dataQuery = {
      client_id,
      fechaDeCorte,
      empresa: this.empresaId,
      adim: true
    };
    return this.http
    .post<DataFactura[]>(this.apiUrl + 'account/balance_account.php', dataQuery)
    .pipe(map(response => response));
  }
  public checkCredit = (clientID: number) => {
    const data = {
      empresa: this.empresaId,
      clientID//cliente.client_number,
    };
    return this.http
      .post<any>(this.apiUrl + 'checkout/check_credit.php', data)
      .pipe(map((response) => response));
  }


  public facturasPagadas = (clienteID: number): Observable<DataFacturaPagada> => {
    const data = {
      empresaID: this.empresaId,
      clienteID,//cliente.client_number,
      option:'getPagosHistorico'
    };
    return this.http
      .post<DataFacturaPagada>(this.apiUrl + 'reportes/edoCuenta/facturasUtilidadesHistorico.php', data)
      .pipe(map((response) => response));
  }



  public getDataBuissness = (cliente: number): Observable<DataBuissness> => {
    // const dataUser: DataUser = this.login.getDataUser();

    const dataQuery = {
      empresa: this.empresaId,
      cliente,
    };
    return this.http.post(this.apiUrl + 'account/get_my_company.php', dataQuery).pipe(map(response => {
      return response[0];
    }));
  }

  public getUsoCdfi = (client_id: number): Observable<DataCFDI> => {
    // const login = this.login.isLogged();
    // const dataUser: DataUser = this.login.getDataUser();

    //  login ? (dataClient.es_publicogeneral === 'SI' ? dataClient.correoVendedor : dataClient.email) : environment.correo
    const opcion = {
      option: 'GetCFDI',
      client_id, // : login ? dataUser.client_number : environment.cliente,
      empresa_id: this.empresaId,
    };
    return this.http
      .post<DataCFDI>(this.apiUrl + 'account/get_uso_cdfi.php', opcion)
      .pipe(
        retry(3),
        // catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };

  public getRegimenFiscal = (client_id: number): Observable<DataRegimenFiscal> => {
    // const login = this.login.isLogged();
    // const dataUser: DataUser = this.login.getDataUser();
    //  login ? (dataClient.es_publicogeneral === 'SI' ? dataClient.correoVendedor : dataClient.email) : environment.correo
    const opcion = {
      option: 'GetRegimen',
      client_id, // : login ? dataUser.client_number : environment.cliente,
      empresa_id: this.empresaId,
    };
    return this.http
      .post<DataRegimenFiscal>(
        this.apiUrl + 'account/get_regimenFiscal.php',
        opcion
      )
      .pipe(
        retry(3),
        // catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };


  public getUlididadHistorico = (clienteID: number): Observable<DataUtilidades> => {

    const opcion = {
      option: 'getUtilidadesHistorico',
      clienteID,
      empresaID: this.empresaId,
    };
    return this.http
      .post<DataUtilidades>(
        this.apiUrl + 'reportes/edoCuenta/facturasUtilidadesHistorico.php',
        opcion
      )
      .pipe(
        retry(3),
        map((response) => {
          return response;
        })
      );
  };



  public getCPApi = (cp) => {
    const dataQuery = {
      codigopostal: cp,
      option: 'getCodigoPostal'
    };
    return this.http.post<any>('https://api.kabik.mx/account/addresses/getCodigoPostal.php', dataQuery)
      .pipe(
        // catchError(this.handleError),
        map(response => {
          return response;
        })
      );
  }

  public updateInformacionFiscal = (data: any, client_id: number, rfc: string, tipoPersona: string): Observable<Respuesta> => {

    data.rfc = rfc;
    data.tipoPersona = tipoPersona;
    const datosQuery = {
      client_id,
      empresa_id: this.empresaId,
      token: localStorage.getItem('KabikSession'),
      a: true,
      fiscal:  btoa(JSON.stringify(data)),
    };
    return this.http.post<Respuesta>(this.apiUrl + 'account/ActualizarMiEmpresaAdmin.php', datosQuery)
      .pipe(
        retry(3),
        catchError(this.handleError),
        map(response => response));
  }

  public uploadFiles = (dataForm) => {
    return this.http
      .post<any>(this.apiUrl + 'account/uploadConstaciaFiscal.php', dataForm)
      .pipe(map(data => {
        return data;
      }));
  }

  public getFiles(rfc: string, clienteID: number): Observable<DataFile> {
    const rutaRfc = rfc === 'XAXX010101000' || rfc === 'xaxx010101000' ?   rfc+'_'+clienteID : rfc;
    const empresa =   Number(this.empresaId) === 1 ? 'Kabik' : 'Letech';
    const data = {
      rfc: rutaRfc,
      clienteID,
      empresa
    };
    return this.http
      .post<DataFile>(
        environment.apiUrl + 'clients/getUploadsWebFiles.php',
        data
      )
      .pipe(map(
                 data => {
                    return data;
                  }));
  }


  private handleError = (error: HttpErrorResponse | any) => {

    Swal.fire({
      type: 'error',
      title: 'Información Fiscal',
      html: 'Lo sentimos, ocurrio un error al actualizar sus datos fiscales.<br/><br/>Error: ' + error.message+'<br/><br/> Detalles: '+error.error.message,

      confirmButtonText: 'Aceptar',
      footer:
        'Contáctanos&nbsp;<a href="mailto:incidencias@kabik.mx">incidencias@kabik.mx</a>',
    });
    return throwError(error);
  }
}
