import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Data, Response } from '../../views/imagenes-faltantes/model-imagenes-faltantes/model-imagenes-faltantes';
import * as FileSaver from 'file-saver';

import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class ImagenesFaltantesService {
  private behaviorSubject = new BehaviorSubject<Response>({ message: '' });
  asObservable = this.behaviorSubject.asObservable();

  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();

  constructor(private http: HttpClient,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
}

  updateObservable(data: Response) {
    this.behaviorSubject.next(data);
  }

  show(data: FormData) {
    return this.http.post<Data>(environment.apiUrl + 'imagenes/subir_imagen_cdn.php', data).pipe(map((data) => data)).toPromise();
  }

  insert(data: FormData) {
    return this.http.post<Response>(environment.apiUrl + 'imagenes/subir_imagen_cdn.php', data).pipe(map((data) => data)).toPromise();
  }

  exportToExcel = (articulos: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      empresaId: this.empresaId,
      articulos: articulos,
      columnas: columnas,
      opcion: 'to_export',
    };
    return this.http.post<any>(environment.apiUrl + 'admin/api_articulos_popup.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_imagenes_faltantes_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
