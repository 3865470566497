import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listaCliente'
})
export class ListaClientePipe implements PipeTransform {

  transform(value: any, args: any): any {
    if(typeof args === undefined || args=== '' || args.length < 2 )return value;

    const filterClientes= [];
      for (const f of value){
     if(f.Nombre.toLowerCase().indexOf(args.toLowerCase())>-1 || f.Rfc.toLowerCase().indexOf(args.toLowerCase())>-1 || f.Correo.toLowerCase().indexOf(args.toLowerCase())>-1 ){
       filterClientes.push(f);
      }
    }
    return filterClientes;
  }

}
