import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import {
  DataPedido,
  ResponseEstatus,
  ResponsePedidoDetalle,
} from '../../views/Multi-inventarios/model/pedidos.model';
import { ResponseEstatus as ResponsecatalogoEstatus } from '../../views/Multi-inventarios/model/estatus.model';
import * as FileSaver from 'file-saver';
import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
interface SelectedItems {
  Id: string;
  Nombre: string;
  Sucursal?: string;
}
@Injectable({
  providedIn: 'root',
})
export class PedidosService {

  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(private http: HttpClient, private login: LoginService,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  getPedidos = (
    fechaInicial: string,
    fechaFinal: string,
    mostrarSoloCedis: number,
    mostrarCancelados: number,
    sucursal: SelectedItems[],
    almacen: SelectedItems[],
    tipoPedido: SelectedItems[],
    cliente?: SelectedItems[],
    option: string = 'GetPedidosV3'
  ): Observable<DataPedido> => {
    const data = {
      option,
      empresaID: this.empresaId,
      fechaInicial,
      fechaFinal,
      mostrarSoloCedis,
      mostrarCancelados,
      usuario: this.login.getUserData().correo,
      area: this.login.getUserData().area,
      sucursal,
      almacen,
      tipoPedido,
      cliente
    };
    return this.http
      .post<DataPedido>(
        environment.apiUrl + 'Servicios-web/Admin/pedidos.php',
        data
      )
      .pipe(
        retry(3),
        catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };

  getPedidosV4 = (
    fechaInicial: string,
    fechaFinal: string,
    mostrarSoloCedis: number,
    mostrarCancelados: number,
    sucursal: SelectedItems[],
    almacen: SelectedItems[],
    tipoPedido: SelectedItems[],
    tipoEntrega:SelectedItems[],
    vendedores?: SelectedItems[],
    cliente?: SelectedItems[],
    colorColumns?:boolean,
    option: string = 'GetPedidosV4',
  ): Observable<DataPedido> => {
    console.log(cliente);
    option = colorColumns ? 'GetPedidosV4':'GetPedidosV3_test';
    const data = {
      option,
      empresaID: this.empresaId,
      fechaInicial,
      fechaFinal,
      mostrarSoloCedis,
      mostrarCancelados,
      usuario: this.login.getUserData().correo,
      area: this.login.getUserData().area,
      sucursal,
      almacen,
      tipoPedido,
      cliente,
      tipoEntrega,
      colorColumns,
      vendedores
    };
    return this.http
      .post<DataPedido>(
        environment.apiUrl + 'Servicios-web/Admin/pedidos.php',
        data
      )
      .pipe(
        retry(3),
        catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };

  // getPedidosFiltros = (fechaInicial: string,
  //                     fechaFinal: string,
  //                     mostrarSoloCedis: number,
  //                     mostrarCancelados: number,
  //                     Sucursal: any,
  //                     Almacen: any,
  //                     TipoPedido: string): Observable<DataPedido> => {
  //   const data = {
  //     option: 'GetPedidosV3',
  //     empresaID: this.empresaId,
  //     fechaInicial,
  //     fechaFinal,
  //     mostrarSoloCedis,
  //     mostrarCancelados,
  //     usuario: this.login.getUserData().correo,
  //     area: this.login.getUserData().area

  //   };

  //   return this.http
  //     .post<DataPedido>(
  //       environment.apiUrl + 'Servicios-web/Admin/pedidos.php', data).pipe(
  //         retry(3),
  //         catchError(this.handleError),
  //         map((response) => {
  //            return response;
  //       }));
  // }

  getDesgloce = (
    almacenID: string,
    pedidoID: string,
    tipoPedido: string
  ): Observable<any> => {
    const data = {
      option: 'GetDesglosado',
      empresaID: this.empresaId,
      almacenID,
      pedidoID,
      tipoPedido,
    };
    return this.http
      .post<any>(environment.apiUrl + 'Servicios-web/Admin/pedidos.php', data)
      .pipe(
        retry(2),
        catchError(this.handleError),
        map((response) => {
          return response.desgloseArticulos;
        })
      );
  };

  getDetalleArticulos = (
    almacenID: string,
    pedidoID: string,
    clienteID: string,
    tipoPedido: number
  ): Observable<ResponsePedidoDetalle> => {
    const data = {
      option: 'GetPedidosDetalles',
      empresaID: this.empresaId,
      almacenID,
      pedidoID,
      clienteID,
      tipoPedido,
    };
    return this.http
      .post<ResponsePedidoDetalle>(
        environment.apiUrl + 'Servicios-web/Admin/pedidos.php',
        data
      )
      .pipe(
        retry(3),
        catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };
  getCatalogoEstatus = (colorColumns?:boolean): Observable<ResponsecatalogoEstatus> => {
    const data = {
      option: 'GetCatalogoEstatus',
      empresaID: this.empresaId,
      usuario: this.login.getUserData().correo,
      area: this.login.getUserData().area,
      colorColumns
    };
    return this.http
      .post<ResponsecatalogoEstatus>(
        environment.apiUrl + 'Servicios-web/Admin/pedidos.php',
        data
      )
      .pipe(
        retry(3),
        catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };
  //  SetEstatus = (pedidoID: string, almacenID: string, estatusID: string, observaciones: string, quien: string) => {
  //   const data = {
  //     option: 'SetEstatus',
  //     empresaID : this.empresaId,
  //     pedidoID,
  //     estatusID,
  //     almacenID,
  //     observaciones,
  //     quien
  //   };
  //   return this.http
  //   .post<any>(environment.apiUrl + 'Servicios-web/Admin/pedidos.php', data)
  //   .pipe(retry(3),
  //        catchError(this.handleError),
  //        map((response) => { return response;
  //           })
  //        );
  //  }

  setEstatusPedido = (dataForm) => {
    return this.http
      .post<ResponseEstatus>(
        environment.apiUrl + 'Servicios-web/Admin/pedidosV4.php',
        dataForm
      )
      .pipe(
        retry(2),
        map((response) => {
          return response;
        })
      );
  };

  SetComprador = (pedidoID: string, almacenID: string, comprador: string) => {
    const data = {
      option: 'SetComprador',
      empresaID: this.empresaId,
      pedidoID,
      comprador,
      almacenID,
    };
    return this.http
      .post<any>(environment.apiUrl + 'Servicios-web/Admin/pedidos.php', data)
      .pipe(
        retry(3),
        catchError(this.handleError),
        map((response) => {
          return response;
        })
      );
  };

  show = (fechaInicial: any, fechaFinal: any) => {
    const data = {
      opcion: 'show',
      empresa: this.empresaId,
      fechaInicial,
      fechaFinal,
    };
    return this.http
      .post<any>(environment.apiUrl + 'admin/api_pedidos.php', data)
      .pipe(map((data) => data))
      .toPromise();
  };

  status = () => {
    const data = {
      opcion: 'status',
      empresa: this.empresaId,
    };
    return this.http
      .post<any>(environment.apiUrl + 'admin/api_pedidos.php', data)
      .pipe(map((data) => data))
      .toPromise();
  };

  insert = (
    IdPedidoEstatus: number,
    IdWsPedido: number,
    almacen: string,
    pedido: number,
    estatus: number,
    observaciones: string
  ) => {
    const data = {
      opcion: 'insert',
      IdPedidoEstatus,
      IdWsPedido,
      empresa: this.empresaId,
      almacen,
      pedido,
      estatus,
      observaciones,
      quien: this.login.getUserData().correo,
    };
    return this.http
      .put<any>(environment.apiUrl + 'admin/api_pedidos.php', data)
      .pipe(map((data) => data));
  };

  existences = (claveArticulo: any) => {
    const data = {
      opcion: 'existences',
      empresa: this.empresaId,
      claveArticulo,
    };
    return this.http
      .post<any>(environment.apiUrl + 'admin/api_pedidos.php', data)
      .pipe(map((data) => data))
      .toPromise();
  };

  exportToExcel = (datos: any, columnas: string[], titulo: string) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      opcion: 'to_export',
      empresa: this.empresaId,
      datos,
      columnas,
      titulo,
    };
    return this.http
      .post<any>(environment.apiUrl + 'admin/api_pedidos.php', data, {
        headers,
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((data) => {
          const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
          const file = new File(
            [blob],
            'reporte_' +
              titulo.toLowerCase() +
              '_kabik_' +
              date.getDate() +
              '_' +
              (date.getMonth() + 1) +
              '_' +
              date.getFullYear() +
              '.xlsx',
            {
              type: 'application/vnd.ms.excel',
            }
          );
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  };

  private handleError = (error: HttpErrorResponse | any) => {
    // this.spinner.hide();
    Swal.fire(
      'Multi Inventario Pedidos',
      'Lo sentimos, ocurrio un error de comunicación.<br/><br/>Error: ' +
        error.message,
      'error'
    );
    return throwError(error);
  };
}
