import { sucursal } from './../../ABC/utils/models/ABC';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientesService } from '../../../services/clientes.service';
import Swal from 'sweetalert2';
import { log } from 'console';
import { DataCFDI, DataRegimenFiscal } from '../model/DataAddress';
import { Observable } from 'rxjs';
import { AccountStatusService } from '../services/account-status/account-status.service';
import { EmpresaService } from '../../../services/Empresa/Empresa.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export interface Cliente {
  tipoDePersona: 'F' | 'M';
  giroComercial?: string;
  razonSocial?: string;
  FNombre?: string;
  FAPaterno?: string;
  FAMaterno?: string;
  rfc: string;
  calle?: string;
  NoExt?: string;
  NoInt?: string;
  colonia?: string;
  ciudad?: string;
  estado?: string;
  telCelular?: string;
  telParticular?: string;
  usuario?: string;
  cfdi?: string; // Asegúrate de que este tipo corresponda con el valor esperado del select de CFDI
  regimenfiscal?: string; // Igual que con CFDI
  nombre?: string; // Para usar en "Detalles de {{cliente?.nombre}}"
  codigoPostal?:string,
  contra :string,
  Id:number
}


@Component({
  selector: 'app-detail-cliente',
  templateUrl: './detail-cliente.component.html',
  styleUrls: ['./detail-cliente.component.scss']
})
export class DetailClienteComponent implements OnInit {
  cliente: Cliente;
  files: any;
  // selectForCFDI;
  vendedores: any;
  tipoPrecio: any;
  loadAltaCliente: boolean;
  public userRfc: string;
  public RegimenFiscal$: Observable<DataRegimenFiscal>;
  public CFDI$: Observable<DataCFDI>;
  public CFDI: string;
  public REGFISC: string;
  colonias = [];
  public filterOptionCP: Observable<string[]>;
  private destroy$ = new Subject<void>();
  empresaId =  String(environment.empresaId);
  sucursales = [];
  constructor(
    private service: AccountStatusService,
    private route: ActivatedRoute,
    private clienteService: ClientesService,
    private router: Router,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
      console.log('is id',id);
      this.sucursales =  id === '1' || id === 1 ? [
        { value: 'Norte', viewValue: 'Norte' },
        { value: 'Lomas', viewValue: 'Lomas' }
      ] : [
        { value: 'Reynosa', viewValue: 'Reynosa' },
        { value: 'Huejutla', viewValue: 'Huejutla' },
        { value: 'RioVerde', viewValue: 'Rio Verde' },
        { value: 'PozaRica', viewValue: 'Poza Rica' },
        { value: 'Tampico', viewValue: 'Tampico' }
      ];
      console.log('sucursales', this.sucursales);
    });

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadClient(params.id,params.cliente_id);
      this.loadFiles(params.id);
    });
    this.getVendedores();
    this.getListRegimenFiscales();
  }

  getListRegimenFiscales() {
    this.RegimenFiscal$ = this.service.getRegimenFiscal(0);
    this.CFDI$ = this.service.getUsoCdfi(0);

  }

  getVendedores() {
    this.clienteService
      .getVendedores()
      .then(res => {
        if (res.message === 'true') {
          this.vendedores = res.vendedores;
          this.tipoPrecio = res.tipoPrecio;
        }
      })
      .catch(err => {
        // console.log(err);
      });
  }

  public searchCP = (): void => {
    if (this.cliente.codigoPostal !== '') {
      this.getCPApi(this.cliente.codigoPostal);
    }
  }
  public getCPApi = (cp: string): void => {
    this.service.getCPApi(cp).subscribe((response) => {
      if (!response.error) {
        const dataResponse = response;
        this.cliente.ciudad =
          dataResponse.municipio === ''
            ? dataResponse.ciudad
            : dataResponse.municipio;

        this.cliente.estado = dataResponse.estado;
        this.colonias = response.asentamiento;

      } else {
        this.colonias = [];
      }
    }, (error) => {
      this.colonias = [];

    });
  }

  loadFiles(rfc: string) {
    this.clienteService.getFiles(rfc).then(res => {
      if (res.message === 'true') {
        this.files = res.archivos;
      }
    });
  }

  loadClient(id,clienteID=0) {
    this.clienteService.getSingleCliente(id,clienteID).then(result => {
      if (result.message === 'true') {
        this.cliente = result.cliente[0];
        this.CFDI = result.cliente[0].USOCFDI;
        this.REGFISC = result.cliente[0].RegimenFiscalSAT;
        this.cliente.cfdi = this.CFDI;
        this.cliente.regimenfiscal = this.REGFISC;
        console.log('linea 62', this.cliente);
        this.userRfc = '';
        this.userRfc = this.cliente.rfc;
        if (this.cliente.rfc === 'XAXX010101000' || this.cliente.rfc === 'xaxx010101000') {
          if (this.cliente.tipoDePersona === 'F') {
            const nombres = this.cliente.FNombre;
            this.userRfc = (this.cliente.rfc + '_' + nombres.split(' ').join('_')).toUpperCase();
          } else if (this.cliente.tipoDePersona === 'M') {
            const nombre = this.cliente.razonSocial;
            this.userRfc = (this.cliente.rfc + '_' + nombre.split(' ').join('_')).toUpperCase();
          }
        }
        this.loadFiles(this.userRfc.toUpperCase());
      }
    });
  }

  verificarCliente(form: any, rfc: string) {
    let emptyValues = Object.keys(form.value)
      .filter(key => form.value[key] == '')
      .reduce((obj, key) => {
        obj[key] = form.value[key];
        return obj;
      }, {});

    let emptyFormulario = Object.keys(this.cliente)
      .filter(key => this.cliente[key] == '')
      .reduce((obj, key) => {
        obj[key] = this.cliente[key];
        return obj;
      }, {});

    if (this.cliente.regimenfiscal === '' || this.cliente.cfdi === '') {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Faltan validar datos: ${Object.keys(emptyFormulario).join(", ")}`,
      })
      return null;
    }

    if (form.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Faltan validar datos: ${Object.keys(emptyValues).join(", ")}`

      });
      return null;
    }

    this.loadAltaCliente = true;
    this.clienteService
      .verificarClienteExistente(rfc)
      .then(res => {
        if (res.message === 'true') {
          this.altaCliente(form, rfc);
        } else {
          this.loadAltaCliente = false;
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: 'Ya existe un cliente con este RFC'
          });
        }
      })
      .catch(err => {
        this.loadAltaCliente = false;
        console.log(err);
      });
  }

  updateCliente(){
    this.loadAltaCliente = true;
    this.clienteService.updateRegistro(this.cliente.Id,this.cliente).then(
      res => {
        this.loadAltaCliente = false;

        if (res.estatus === 'Success' && res.error == false) {
           Swal.fire({
            type: 'success',
            title: 'Informacion actualizada',
            text: 'La informacion se actualizo correctamente.'
          });
        }else{
          Swal.fire({
            type: 'error',
            title: 'Ocurrió un problema',
            text: 'Ocurrio un error al actualizar los datos de cliente, error: ' + res.error
          });
        }
      }).catch(err => {
        this.loadAltaCliente = false;
        console.log('err',err)
        Swal.fire({
          type: 'error',
          title: '¡ERROR!',
          text: 'Ocurrio un error al actualizar la informacion del cliente',
          showConfirmButton: true,
          confirmButtonText: 'De acuerdo',
          confirmButtonClass: 'btn-succes'
        })
      });
  }

  altaCliente(form: any, rfc?: string) {
    if (form.valid) {
      const formData = form.value;
      const cliente = this.cliente;
      const sucursal = formData.sucursal;
      const vendedor = formData.vendedor;
      const tipoPrecio = formData.Precio;
      const cfdi = cliente.cfdi;
      const regimenFiscal = cliente.regimenfiscal;
      let nombre;
      // console.log(this.cliente);
      if (this.cliente.tipoDePersona === 'M') {
        nombre = this.cliente.razonSocial;
      } else {
        nombre =
          this.cliente.FNombre +
          ' ' +
          this.cliente.FAPaterno +
          ' ' +
          this.cliente.FAMaterno;
      }
      this.loadAltaCliente = true;
      this.clienteService
      .altaCliente(this.cliente.Id, sucursal, vendedor, this.cliente.usuario, tipoPrecio, cfdi, regimenFiscal)
      .then(res => {
              if (res.message === 'true') {

                this.clienteService
                  .altaClienteAccesos(
                    res.numeroCliente,
                    sucursal,
                    nombre,
                    this.cliente.contra,
                    this.cliente.usuario
                  )
                  .then(resultado => {
                    // console.log(resultado);
                    if (resultado.message === 'true') {
                      this.loadAltaCliente = false;
                      if (this.cliente.rfc === 'XAXX010101000' || this.cliente.rfc === 'xaxx010101000') {
                        this.clienteService.renameFiles(this.cliente.rfc, this.userRfc, res.numeroCliente).then((res: any) => {
                          // console.log('line 139', res);
                          console.log('***79***', this.cliente.rfc, this.userRfc, res.numeroCliente);
                        });
                      }
                      Swal.fire({
                        type: 'success',
                        title: 'Correcto',
                        text: 'El número de cliente es: ' + res.numeroCliente
                      }).then(result => {
                        console.log("result")
                        this.router.navigate(['/clientes/list']);
                      });
                    } else {
                      this.loadAltaCliente = false;
                      Swal.fire({
                        type: 'success',
                        title: 'Correcto',
                        text:
                          'El cliente se generó con el número: ' +
                          res.numeroCliente +
                          ', pero no se pudieron crear sus accesos a la página web, contacte al administrador. Error: ' +
                          res.error
                      }).then(result=>{
                        console.log("Result")
                        this.router.navigate(['/clientes/list']);
                      });
                    }


                  })
                  .catch(error => {
                    this.loadAltaCliente = false;
                    Swal.fire({
                      type: 'success',
                      title: 'Correcto',
                      text:
                        'El cliente se generó con el número: ' +
                        res.numeroCliente +
                        ', pero no se pudieron crear sus accesos a la pagina web, contacte al administrador.'
                    }).then(result =>{
                      console.log('Result')
                      this.router.navigate(['/clientes/list']);
                    });


                  });
                // Swal.fire({
                //   type: 'success',
                //   title: 'Correcto',
                //   text: 'El número de cliente es: ' + res.numeroCliente
                // });
              } else {
                this.loadAltaCliente = false;
                Swal.fire({
                  type: 'error',
                  title: 'Ocurrió un problema',
                  text: 'No se pudo dar de alta al cliente, error: ' + res.error
                });
              }
      })
      .catch(err => {
              this.loadAltaCliente = false;
              console.log(err);
              Swal.fire({
                type: 'error',
                title: 'Ocurrió un problema',
                text:
                  'No se pudo dar de alta al cliente, contacte al administrador.'
              });
      });
    } else {
      this.loadAltaCliente = false;
      alert('Llena los campos de Sucursal, Vendedor y Tipo de cliente');
    }
  }

  delete(rfc, correo,id) {
    Swal.fire({
      type: 'warning',
      title: '¡ADVERTENCIA!',
      text: 'Esté cambio no se puede revertir, ¿desea continuar?',
      showConfirmButton: true,
      confirmButtonText: 'De acuerdo',
      confirmButtonClass: 'btn-danger'
    }).then(res => {
      if (res.value) {
        this.clienteService
          .deleteCliente(rfc, correo,id)
          .then(res => {
            if (res.message === 'true') {
              this.clienteService
                .deleteFiles(rfc)
                .then(res => {
                  if (res.message === 'true') {
                    Swal.fire({
                      type: 'success',
                      title: '¡EXITO!',
                      text: 'Se eliminó el registro corretamente',
                      showConfirmButton: true,
                      confirmButtonText: 'De acuerdo',
                      confirmButtonClass: 'btn-succes'
                    }).then(res => {
                      this.router.navigate(['/clientes/list']);
                    });
                  } else {
                    Swal.fire({
                      type: 'error',
                      title: '¡ERROR!',
                      text:
                        'No se eliminaron los archivos, contacta al administrador. Clave de carpeta: ' +
                        rfc,
                      showConfirmButton: true,
                      confirmButtonText: 'De acuerdo',
                      confirmButtonClass: 'btn-succes'
                    }).then(res => {
                      this.router.navigate(['/clientes/list']);
                    });
                  }
                })
                .catch(err => {
                  Swal.fire({
                    type: 'error',
                    title: '¡ERROR!',
                    text:
                      'No se eliminaron los archivos, contacta al administrador. Clave de carpeta: ' +
                      rfc,
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo',
                    confirmButtonClass: 'btn-succes'
                  }).then(res => {
                    this.router.navigate(['/clientes/list']);
                  });
                });
            } else {
              Swal.fire({
                type: 'error',
                title: '¡ERROR!',
                text: 'No se eliminó elregistro, contacta al administrador.',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo',
                confirmButtonClass: 'btn-succes'
              }).then(res => {
                this.router.navigate(['/clientes/list']);


              });
            }
          })
          .catch(err => {
            Swal.fire({
              type: 'error',
              title: '¡ERROR!',
              text: 'No se eliminó elregistro, contacta al administrador.',
              showConfirmButton: true,
              confirmButtonText: 'De acuerdo',
              confirmButtonClass: 'btn-succes'
            }).then(res => {
              this.router.navigate(['/clientes/list']);
            });
          });
      }
    });
  }
}
