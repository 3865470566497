import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface Participacion {
  message: string;
  listClientes?: {};
}

@Injectable({
  providedIn: 'root'
})
export class ReporteListaClienteService {

  constructor(private http: HttpClient) { }

  public getListClientes(idEmpresa: any) {
    const data = {
      IdEmpresa: idEmpresa,
      option: 'getListadoClientes',
      test: true
    };
    return this.http
      .post<Participacion>(
        environment.apiUrl + 'reportes/reporte-listado-clientes/getListClientes.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }
  exportAsExcelFileReporteClientes(lista: any, excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lista);

    const workbook: XLSX.WorkBook = {
      Sheets: { Clientes: worksheet },
      SheetNames: ['Clientes'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + 'Kabik_' + new Date().toLocaleDateString() + EXCEL_EXTENSION
    );
  }




}
