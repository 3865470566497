import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbDate, NgbCalendar, NgbPeriod } from '@ng-bootstrap/ng-bootstrap';
import { ReporteListaClienteService } from '../../services/reportes/reporte-lista-cliente.service';
@Component({
  selector: 'app-reporte-lista-cliente',
  templateUrl: './reporte-lista-cliente.component.html',
  styleUrls: ['./reporte-lista-cliente.component.scss']
})
export class ReporteListaClienteComponent implements OnInit {
  filterClientes ='';
  listaArray: [];
  listaEmail: any;
  filtro: any;
  filtroR: any;
  length: number;
  page: number = 1;
  pageSize: number = 15;
  pageActual :number=1;
  constructor(private spinner: NgxSpinnerService, private listCliente: ReporteListaClienteService ) {
  }

  getListClientes(){

    this.spinner.show('spinner-listClientes');
    this.listCliente.getListClientes(2)
    .then((res) => {
      if(res.message === 'true') {
        let listArray: any = res.listClientes;
        this.listaArray = listArray;
        this.filtroR   = listArray;
        this.length    = listArray.length;


      }

      this.spinner.hide('spinner-listClientes');
    });

  }
  exportToExcel(): void {

    this.listCliente.exportAsExcelFileReporteClientes(
      this.listaArray,
      'Reporte_Clientes_'
    );
  }

  filtroTpPedido(sucursal:String){

    this.filtro === "";
    this.listaArray  = this.filtroR;
    switch(sucursal){
      case 'Tampico':
      case 'Rio Verde':
      case 'POZA RICA':
      case 'Reynosa':
      case 'Huejutla':
       this.filtro= this.listaArray.filter(menuData => menuData["Sucursal"] === sucursal);
        break;
      case 'Todo':
       this.filtro  = this.filtroR;
        break;


    }
    this.listaArray= this.filtro;
    this.length = this.filtro.length;

  }

  ngOnInit() {
    this.getListClientes();
  }



}
