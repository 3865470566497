import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { EmpresaService } from '../../../../services/Empresa/Empresa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-edit-externas',
  templateUrl: './view-edit-externas.component.html',
  styleUrls: ['./view-edit-externas.component.scss'],
})
export class ViewEditExternasComponent implements OnInit {
  @Input()
  public imagenNombre: string;
  @Output()
  public setEliminatedImge = new EventEmitter<boolean>();
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  public ruta = environment.apiUrl + 'imagenes_pagina/Kabik/Pagina/';
  constructor(    private empresaService: EmpresaService
  ) {

    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
      const newRuta =  id === '1' || id === 1 ?'/Kabik':'/Letech';
      this.ruta = environment.apiImage +newRuta+'/Pagina/';
    });
  }

  ngOnInit() {
    this.ruta += this.imagenNombre;
  }

  public eliminarImagen() {
    Swal.fire({
      type: 'warning',
      title: '¿Subir nueva imagen?',
      text: 'Este cambio se guardará una vez que haya enviado toda la infomación',
      confirmButtonText: 'Si, subir nueva',
      showCancelButton: true,
      cancelButtonText: 'No, dejar actual',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      focusConfirm: false,
      focusCancel: true,
    }).then((res) => {
      if (res.value) {
        this.setEliminatedImge.emit(true);
      }
    });
  }
}
