import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import { EmpresaService } from '../../services/Empresa/Empresa.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private empresaService: EmpresaService) { }
  public version = environment.version;
  empresaId
  ngOnInit() {
    this.empresaService.empresaId$.subscribe(id => {
     this.empresaId = id;
    });
  }

}
